import React from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { PlayIcon } from '@/icons/play';
import { BookmarkIcon, CheckIcon } from '@heroicons/react/outline';
import { useTranslation } from 'next-i18next';
import useWhislist from '@/hooks/useWhislist';
import awsImageLoader from '@/utils/awsImageLoader';
import { isSeries } from '@/utils/index';

type Props = {
  promotion: any;
};

const PromotionBanner: React.FC<Props> = ({ promotion }: Props) => {
  const { locale, push } = useRouter();
  const { t } = useTranslation('common');
  const { isOnWhislist, toggleContent } = useWhislist();

  const handlePlayClick = React.useCallback(() => {
    const { slug, content_type } = promotion.contents;
    push(
      isSeries(content_type) ? `/series/${slug}` : `/movie/${slug}`,
      undefined,
      { locale }
    );
  }, [locale, promotion, push]);

  return (
    <div className="relative cursor-pointer">
      <div className="hidden md:block">
        <Image
          loader={awsImageLoader}
          src={promotion.image_name}
          layout="responsive"
          width={1920}
          height={720}
          priority
          alt={promotion.title}
        />
      </div>
      <div className="relative flex items-center justify-center md:hidden h-52 rounded-lg overflow-hidden bg-black m-4">
        <Image
          loader={awsImageLoader}
          src={promotion.image_name}
          layout="fill"
          priority
          alt={promotion.title}
        />
        <div
          className="bg-black bg-opacity-10 backdrop-blur z-10 rounded-full w-16 h-16 flex items-center justify-center"
          onClick={handlePlayClick}
        >
          <PlayIcon className="w-5" />
        </div>
      </div>
      <div className="hidden md:block absolute left-0 bottom-0 right-0 w-full text-white bg-gradient-to-t from-[#000000cc] to-transparent">
        <div className="container mx-auto flex flex-col py-8 px-16 xl:px-0">
          <div className="font-semibold text-2xl md:text-3xl">
            {promotion.title}
          </div>
          <div className="flex space-x-2 py-4">
            <div className="tag">{promotion.contents[`genre_${locale}`]}</div>
            <div className="tag">{promotion.contents.production_year}</div>
            <div className="tag">{promotion.contents[`country_${locale}`]}</div>
          </div>
          <div className="flex space-x-3">
            <button
              className="rounded-lg bg-accent px-4 py-2 flex items-center space-x-2 text-sm font-semibold"
              onClick={handlePlayClick}
            >
              <PlayIcon className="w-4" />
              <span>{t('play')}</span>
            </button>
            <button
              className="rounded-lg bg-white bg-opacity-10 px-4 py-2 flex items-center space-x-1 text-sm font-semibold "
              onClick={(event) => toggleContent(event, promotion.contents)}
            >
              {!isOnWhislist(promotion.contents) ? (
                <>
                  <BookmarkIcon className="w-6" />
                  <span>{t('queue')}</span>
                </>
              ) : (
                <>
                  <CheckIcon className="w-6" />
                  <span>{t('queued')}</span>
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromotionBanner;
